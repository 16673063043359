import * as React from 'react';
import ValidateAddressContext from 'contexts/ValidateAddressContext/ValidateAddressContext';
import ValidatedAddressesModal from 'bundles/App/pages/Account/Addresses/ValidatedAddressesModal';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { getValidAddresses } from 'api/addressValidation';
import { Status } from 'libs/utils/api/types';
import Toast from 'utils/toast';
import AppContext from 'contexts/AppContext/AppContext';

interface Props {
  children?: React.ReactNode;
  onSaveValidAddress: (address: Address) => void;
  allowUnverifiedStreet?: boolean;
  admin?: boolean;
  selectAddressWithoutSaving?: boolean;
}

export const dpvMatchCodeErrors = {
  N: 'Could not validate address. Please double check the street number or zipcode is correct.',
  S: 'Could not validate address. Secondary address information was not recognized.',
  D: 'Could not validate address. Secondary address information required and not provided.',
};

const ValidateAddressContextContainer = ({
  admin = false,
  allowUnverifiedStreet = false,
  children,
  onSaveValidAddress,
  selectAddressWithoutSaving = false,
}: Props) => {
  const appContext = React.useContext(AppContext);
  const [showVerifyModal, setShowVerifyModal] = React.useState(false);
  const [editingAddress, setEditingAddress] = React.useState<Address>(null);
  const [validatedAddresses, setValidatedAddresses] = React.useState<Address[]>(null);
  const [dpvMatchCode, setDpvMatchCode] = React.useState<'N' | 'Y' | 'D' | 'S'>(null);

  const validateAddress = (id: number, address: Address) => {
    if (address.verified) {
      onSaveValidAddress(address);
      return;
    }

    getValidAddresses(id, address).then(res => {
      if (res.status === Status.Ok) {
        if (res.payload.dpvMatchCode !== 'Y' && !allowUnverifiedStreet) {
          Toast.error(dpvMatchCodeErrors[res.payload.dpvMatchCode]);
        } else {
          const unverifiedAddress = address;
          const optionalAddresses = res.payload.addresses.map(a => ({
            ...unverifiedAddress,
            address1: a.street,
            address2: a.secondary,
            city: a.city,
            zipcode: a.zip,
            stateId: appContext.store.states.find(s => s.abbr === a.state).id,
            countryId: 214,
            verified: res.payload.dpvMatchCode === 'Y',
            residential: a.residential,
          }));
          setEditingAddress(unverifiedAddress);
          setValidatedAddresses(optionalAddresses);
          setDpvMatchCode(res.payload.dpvMatchCode);
          setShowVerifyModal(true);
        }
      } else {
        Toast.error(
          'Could not validate address. Please double check the street number or zipcode is correct',
        );
      }
    });
  };

  const contextValue = React.useMemo(
    () => ({
      validateAddress,
    }),
    [validateAddress],
  );

  return (
    <ValidateAddressContext.Provider value={contextValue}>
      {showVerifyModal && (
        <ValidatedAddressesModal
          admin={admin}
          onClose={() => {
            setShowVerifyModal(false);
            setEditingAddress(null);
            setValidatedAddresses(null);
          }}
          onSaveValidAddress={onSaveValidAddress}
          unverifiedAddress={editingAddress}
          validatedAddresses={validatedAddresses}
          selectAddressWithoutSaving={selectAddressWithoutSaving}
          dpvMatchCode={dpvMatchCode}
        />
      )}
      {children}
    </ValidateAddressContext.Provider>
  );
};

export default ValidateAddressContextContainer;
