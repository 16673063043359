import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import * as React from 'react';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import AppContext from 'contexts/AppContext/AppContext';

interface Props {
  title?: string;
  address: Address;
  withTitles?: boolean;
  asGrid?: boolean;
}

const AddressItem = ({ title, address, withTitles, asGrid }: Props) => {
  const appContext = React.useContext(AppContext);
  const abbr = appContext.store.states.find(s => address.stateId === s.id)?.abbr;
  const country = address.country?.iso || 'US';

  return (
    <Grid.Row className="pl-4 -md:text-sm">
      <Grid.Col className={asGrid && '!grid !grid-cols-3'}>
        {!!title && (
          <Grid.Row className="mb-4 !justify-start !font-hvBold">
            <Span>{title}</Span>
            <br />
          </Grid.Row>
        )}
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">Address Name: </Span>}

          <Span>{address.name}</Span>
          <br />
        </Grid.Row>
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">Company: </Span>}

          <Span>{address.company}</Span>
        </Grid.Row>
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">Address 1: </Span>}
          <Span>{address.address1}</Span>
        </Grid.Row>
        {address?.address2 && (
          <Grid.Row className="!justify-start">
            {withTitles && <Span className="font-hvBold mr-2">Address 2: </Span>}
            <Span>{address?.address2}</Span>
          </Grid.Row>
        )}
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">City: </Span>}
          <Span className="mr-2">{address.city}</Span>
          {withTitles && <Span className="font-hvBold mr-2">State: </Span>}
          <Span className="mr-2">{abbr}</Span>
          {withTitles && <Span className="font-hvBold mr-2">Zipcode: </Span>}
          <Span className="mr-2">{address.zipcode}</Span>
        </Grid.Row>
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">Country: </Span>}
          <Span>{country}</Span>
        </Grid.Row>
        <Grid.Row className="!justify-start">
          {withTitles && <Span className="font-hvBold mr-2">Phone: </Span>}
          <Span>{address.phone}</Span>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default AddressItem;
