import * as React from 'react';
import { Address } from 'bundles/App/pages/Account/Addresses/types';

export interface ValidateAddressContextType {
  validateAddress: (id: number, address: Address) => void;
}

const ValidateAddressContext = React.createContext<ValidateAddressContextType>(null);

export default ValidateAddressContext;
