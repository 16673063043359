import * as React from 'react';
import { CreditCard } from 'bundles/App/pages/Account/CreditCards/types';
import { getCreditCardIconByProcessor } from 'styleguide/components/Formik/Input/CreditCardInput';
import { FaDollarSign } from '@react-icons/all-files/fa/FaDollarSign';
import { StoreCredit } from 'api/orders/types';

interface Props {
  paymentSource: CreditCard | StoreCredit;
}

const CreditCardLabel = ({ paymentSource }: Props) => {
  const isCreditCard = (source: unknown): source is CreditCard => true;

  const getDigits = (source: unknown) => {
    if (isCreditCard(source)) {
      return `XXXX-XXXX-XXXX-${source.lastDigits}`;
    }
    return 'Store Credit';
  };

  const getIcon = (source: unknown) => {
    if (isCreditCard(source)) {
      return getCreditCardIconByProcessor(source.ccType);
    }
    return FaDollarSign;
  };

  return (
    <>
      {React.createElement(getIcon(paymentSource), { className: 'mt-0.5 mr-2 flex' })}
      <div className="text-sm">{getDigits(paymentSource)}</div>
    </>
  );
};

export default CreditCardLabel;
