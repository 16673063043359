import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { ApiResponse, SharedErrors } from 'libs/utils/api/types';
import { SmartyAddressesResponse } from 'api/types';
import apiCall from 'libs/utils/api/apiCall';

export const getValidAddresses = (
  id?: number,
  address?: Address,
): ApiResponse<SmartyAddressesResponse, SharedErrors> =>
  apiCall.post<SmartyAddressesResponse, SharedErrors>({
    url: `/address_validations/valid_addresses`,
    data: { address, id },
    unscoped: true,
  });
